import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
// import Button from "@mui/material/Button";
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from './dialogChildren';
import defaults from './defaults';

const CreateTenancyBtn = withSubtheme(props => {
    const {
        // btnLabelProps,
        modalTitle,
        // btnLabel,
        // btnProps,
        property,
        // reloadDataFun,
        maxDialogW,
        // openModal,
        // openRoomDetailsFun,
        openRoomDetails,
        setOpenRoomDetails,
        // link,
    } = props

    // const label = btnLabel ? btnLabel : btnLabelProps;

    const [open, setOpen] = React.useState(openRoomDetails || false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setOpenRoomDetails(false)
    };

    if (property.room_details.length < 1)
        return <></>

    return(
        <>
            {/*<Button {...btnProps} className="btn contact-btn" onClick={handleClickOpen}>{label}</Button>*/}
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                property={property}
                children={<DialogChildren
                    handleClose={handleClose} roomDetails={property?.room_details}
                />}
                title={modalTitle ? modalTitle : "Room Dimensions"}
                maxDialogWidth={maxDialogW}
            />
        </>
    )
}, 'makeOfferBtn', defaults)

export default CreateTenancyBtn;
