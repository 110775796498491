import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import Button from "@mui/material/Button";
import defaults from './defaults';

const HomeReportBtnWrap = withSubtheme(props => {
    const {
        btnLabel,
        btnProps,
        property,
    } = props

    const handleClickOpen = () => {
        window.open(property?.home_report_url, "_blank")
    };

    return(
        <>
            <Button {...btnProps} className="btn homeReport-btn" onClick={handleClickOpen}>{btnLabel}</Button>
        </>
    )
}, 'homeReportBtn', defaults)

export default HomeReportBtnWrap;
