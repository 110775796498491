/* eslint-disable import/first */
import React from "react";
import { MyaccountStoreProvider } from "@myaccountServices";
import PropertyDetailWrapper from '@myaccount/property/propertyDetails/';
import GlobalLayout from "@myaccount/GlobalLayout"

const PropertyDetailPage = ({slug}) => {
    // slug has id as last value after `-`
    const id = slug.split('-').pop()
    return (
        <GlobalLayout>
            <MyaccountStoreProvider>
                <PropertyDetailWrapper id={id} />
            </MyaccountStoreProvider>
        </GlobalLayout>
    );
}

export default React.memo(PropertyDetailPage);
