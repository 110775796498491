import React from "react"
import { withSubtheme } from "@starberryComponentsMui"
import Grid from "@mui/material/Grid"
import Typography from '@mui/material/Typography';
import defaults from "./dialogChildrenDefaults"

const DialogChildren = withSubtheme(props => {
    const { roomDetails, containerProps, gridItemProps } = props
    return (
        <>
        {roomDetails.map((room) => {
            if (!room.dimensions)
                return <></>

            return (
                <Grid
                    {...containerProps}
                >
                    <Grid {...gridItemProps}>
                        <Typography classes={{"root": "item-title"}} variant={`body1`}>{room.name}</Typography>
                    </Grid>
                    <Grid {...gridItemProps}>
                        <Typography classes={{"root": "item-desc"}} align="right" variant={`body1`}>{room.dimensions}</Typography>
                    </Grid>
                </Grid>
            )
        })}
        </>
    )
},"dialogChildren", defaults)

export default DialogChildren
