import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import defaults from './defaults';
import { StarberryIcons } from '@myaccount/icons';
import Button from '@mui/material/Button';

const BackButton = withSubtheme(({ className, buttonProp, btnLabel, setIcon, iconProps }) => {
    // Check if window is defined (so if in the browser or in node.js).
    const isBrowser = typeof window !== "undefined"
    function handleBack() {
        if (isBrowser) {
            window.history.back();
        }
    }
    return(
        <Button {...buttonProp} classes={{root: className}} onClick={() => handleBack()} onKeyPress={handleBack}><StarberryIcons iconName={setIcon} {...iconProps} />{btnLabel}</Button>
    )
}, "backButton", defaults)

export default BackButton
